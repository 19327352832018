import SbPolyfill from "SB/js/polyfill";

(() => {
    //hide contact fab when header is no longer visible
    let sessionFab = document.querySelector('.session-fab');
    let sessionHeaderButton = document.querySelector('.primary-navigation .button-session');

    // let contactFooter = document.querySelector('.footer');
    // let pagination = document.querySelector('.pagination-page');

    let items = document.querySelectorAll('.projects-featured-home .item');

    SbPolyfill('intersection-observer')
    .then(() => {
        if (sessionHeaderButton) {
            const headerObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        sessionFab && sessionFab.classList.remove('active');
                    } else {
                        sessionFab && sessionFab.classList.add('active');
                    }
                });
            }, {threshold: [0]});

            headerObserver.observe(sessionHeaderButton);
        } else {
            sessionFab && sessionFab.classList.add('active');
        }


        //apply classes to items for effects
        const itemObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, {threshold: [0.4]});

        items.forEach((item) => {
            itemObserver.observe(item);
        });
    });

})();
