import React from "react";

export default ({avatar, initials, ...props})=> {
  var classes = ["avatar"];

  if (props.classes) {
    // ensure list of classes is  unique
    classes = [...new Set([...props.classes, ...classes])];
  }

  var image = null;
  if (avatar) {
    let src = typeof(avatar) === "string" ? avatar : avatar.src;
    image = <img className="avatar-image" alt="" src={src} />
  } else if (initials) {
    image = (
      <svg className="avatar-initials" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <text className="avatar-initials-svg-text" textAnchor="middle" transform="translate(24 32)">{ initials }</text>
      </svg>
    );
  }
  return (
    <div className={classes.join(" ")}>
      <div className="avatar-circle">
    
        
        { image }
      </div>
    </div>
  );
}
