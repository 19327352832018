import React from "react";

export default ({loaded = 0, total = 0}) => {

  if (!total) {
    return null;
  }
  var percent = (loaded / total) * 100;

  return (
    <div className="image-uploader-progress">
      <div 
        className="image-uploader-percenter"
        style={{width: percent + '%'}}
      ></div>
    </div>
  );
}
